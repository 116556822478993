@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.rich-text-editor {
  @apply border border-gray-100 rounded-md p-2;
}

.rich-text-editor ul {
  @apply list-disc pl-5;
}

.rich-text-editor ol {
  @apply list-decimal pl-5;
}

.rich-text-editor li {
  @apply mb-1;
}

.prose ul,
.prose ol {
  @apply ml-6;
}

.prose ul {
  @apply list-disc;
}

.prose ol {
  @apply list-decimal;
}

.prose li {
  @apply mb-2;
}

.prose a {
  @apply text-blue-600 underline;
}

.ProseMirror {
  padding: 5px;
}

.ProseMirror a {
  color: #3182ce;
}
